import { Button, Image, Input, Select } from "antd"
import Header from "../header"
import { useState } from "react"
import TextArea from "antd/es/input/TextArea"
import { FaCheck, FaLocationArrow } from "react-icons/fa";
import { FaPhoneSquareAlt,FaInstagramSquare,FaYoutube,FaFacebookSquare } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { FaSquareWhatsapp } from "react-icons/fa6";
import Footer from "../footer";
import { FaMapLocationDot } from "react-icons/fa6";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

let listService =[
       "Ambur",         "Aruppukottai",
"Chidambaram",
"Cumbum",
"Devakottai",
"Dindigul",
"Kallakurichi",
"Karaikal",
"Kovilpatti",
"Manamadurai",
"Nagapattinam",
"Nilgiris",
"Paramakudi",
"Pollachi",
"Rameswaram",
"Sankarankoil",
"Sivakasi",
"Thanjavur",
"Thoothukudi",
"Tirunelveli",
"Tiruvallur",
"Udumalaipettai",
"vilathikulam",
"Arani",
"Bangalore",
"Chengam",
"Coimbatore",
"Dharmapuri",
"Erode",
"Kanchipuram",
"Karaikudi",
"Krishnagiri",
"Manaparai",
"Nagercoil",
"Oddanchatram",
"Pattukottai",
"Rajapalyam",
"Ranipet",
"Sattur",
"Srivilliputhur",
"Theni",
"Thuraiyur",
"Tirupattur",
"Tiruvannamalai",
"Velankanni",
"Viluppuram",
"Ariyalur",
"Bodi",
"Chennai",
"Cuddalore",
"Dharapuram",
"Gudiyatham",
"Kanyakumari",
"Karur",
"Madurai",
"Mayiladuthurai",
"Namakkal",
"Palani",
"Perambalur",
"Ramanathapuram",
"Salem",
"Sivaganga",
"Tenkasi",
"Thondi",
"Tiruchirappalli",
"Tiruppur",
"Tiruvarur",
"Vellore, Virudhunagar"
]
const localPackage =[
  {name:"PACKAGE TYPE", name_one:'SEDAN',name_two:"MUV"},
  {name:"4 hrs & 40 kms"	,seran:"Rs. 1250",	muv:"Rs. 1700"},
  {name:"5 hrs & 50 kms"	,seran:"Rs. 1450",	muv:"Rs. 1950"},
  {name:"6 hrs & 60 kms"	,seran:"Rs. 1650",	muv:"Rs. 2200"},
  {name:"7 hrs & 70 kms"	,seran:"Rs. 1850",	muv:"Rs. 2450"},
  {name:"8 hrs & 80 kms"	,seran:"Rs. 2050",	muv:"Rs. 2700"},
  {name:"9 hrs & 90 kms"	,seran:"Rs. 2250",	muv:"Rs. 2950"},
  {name:"10 hrs & 100 kms"	,seran:"Rs. 2450",	muv:"Rs. 3200"},
  {name:"11 hrs & 110 kms"	,seran:"Rs. 2650",	muv:"Rs. 3450"},
  {name:"12 hrs & 120 kms"	,seran:"Rs. 2850",	muv:"Rs. 3700"},
  {name:"Additional Hrs"	,seran:"Rs. 200/hr",	muv:"Rs. 250/hr"},
  {name:"Additional KMs"	,seran:"Rs. 20/km",	muv:"Rs. 25/km"}
  
]
const stateList = [
       "Ariyalur",
       "Chennai",
       "Coimbatore",
       "Cuddalore",
       "Dharmapuri",
       "Dindigul",
       "Erode",
       "Kanchipuram",
       "Kanyakumari",
       "Karur",
       "Krishnagiri",
       "Madurai",
       "Nagapattinam",
       "Namakkal",
       "Nilgiris",
       "Perambalur",
       "Pudukkottai",
       "Ramanathapuram",
       "Salem",
       "Sivaganga",
       "Thanjavur",
       "Theni",
       "Thoothukudi (Tuticorin)",
       "Tiruchirappalli",
       "Tirunelveli",
       "Tiruppur",
       "Tiruvallur",
       "Tiruvannamalai",
       "Tiruvarur",
       "Vellore",
       "Viluppuram",
       "Virudhunagar"    ]
     const servicePlaces= [{
       name:"Mayiladuthurai",location:"Mayiladuthurai"    },
     {
       name:"Pondicherry",location:"Pondicherry"    },
     {
       name:"Thiruvarur",location:"Thiruvarur"    },
     {
       name:"Chidambaram",location:"Chidambaram"    },
     {
       name:"Cuddalore",location:"Cuddalore"    },
     {
       name:"Vellankanni",location:"Vellankanni"    },
     {
       name:"Salem",location:"Salem"    },
     {
       name:"Madurai",location:"Madurai"    },
     {
       name:"Coimbatore",location:"Coimbatore"    },{
     
     name:"Chennai",location:"Chennai"    },
     
     ]
const TraiffScreen = () =>{
  let history = useNavigate()
       const [cityList, setcityList] = useState(stateList)
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
const onSearch = (value) => {
       console.log('search:', value);
     };
const generateLableValue =(data,stateName)=>{
let a =[]
cityList.map((value)=> {
if(stateName && value!=stateName){
a.push({value: value,label: value,})
}else if(!stateName){
a.push({value: value,label: value,})
}

})

return a




}
const sendMessage = () => {
  const phoneNumber = '+918825775128'; // Replace with your phone number
  const message = encodeURIComponent('Hello, I am interested in your services.');

  window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
};
       return <div>
<Header enableStyle={true} />
<MobileView>
<div className="joinus-header" style={{height:'100px'}}>
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}><div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Tariff</div>
</div>
<div className="header-joinus-title" style={{fontSize:isMobile?"23px":"48px"}}>
TARIFF

</div>
</div>
</MobileView>

<BrowserView>
<div className="joinus-header">
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}><div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Tariff</div>
</div>
<div className="header-joinus-title" style={{fontSize:isMobile?"23px":"48px"}}>
TARIFF

</div>
</div></BrowserView>

{/* budgettaxi@gmail.com */}
<BrowserView>
<div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'20px'}}>
  
<div className="contact-us-contact-new">
     <div className="contact-us-contact-ctn tariff-box" ><span className="contact-us-contact-ctn-text tariff-text">One Way Sedan</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><span className="tariff-text-1">₹ 13/km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">One Way Minimum 130 km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'55px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'20px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>

       <div className="contact-us-contact-new">
     <div className="contact-us-contact-ctn tariff-box" ><span className="contact-us-contact-ctn-text tariff-text">One Way SUV</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><span className="tariff-text-1">₹ 18/km</span></div>
     
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">One Way Minimum 130 km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'55px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'20px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>

       <div className="contact-us-contact-new">
     <div className="contact-us-contact-ctn tariff-box" ><span className="contact-us-contact-ctn-text tariff-text">Two Way Sedan</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><span className="tariff-text-1">₹ 12/km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Two Way Minimum 250 km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'55px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'20px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>
       <div className="contact-us-contact-new">
     <div className="contact-us-contact-ctn tariff-box" ><span className="contact-us-contact-ctn-text tariff-text">Two Way SUV</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><span className="tariff-text-1">₹ 17/km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Two Way Minimum 250 km</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn"><span className="tariff-text-2">Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'55px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'20px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>
</div></BrowserView>

<MobileView>
<div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'20px'}}>
<div className="contact-us-contact-new-mobile" style={{height:'400px'}}>
     <div className="contact-us-contact-ctn tariff-box" style={{height:'60px'}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:'19px'}}>One Way Sedan</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><span className="tariff-text-1" style={{fontSize:'16px'}}>₹ 13/km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>One Way Minimum 130 km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'44px',width:'190px',borderRadius:'5px',
     
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'18px',fontWeight:'bold'}} className="join-us-home-btn" >Book Now</Button>
       </div></div>
       </div>
       </div>
       <div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'20px'}}>
       <div className="contact-us-contact-new-mobile" style={{height:'400px'}}>
     <div className="contact-us-contact-ctn tariff-box" style={{height:'60px'}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:'19px'}}>One Way SUV</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><span className="tariff-text-1" style={{fontSize:'16px'}}>₹ 18/km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>One Way Minimum 130 km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1"><div>

     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'44px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'18px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>
       </div>
       <div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'20px'}}>
       <div className="contact-us-contact-new-mobile" style={{height:'400px'}}>
     <div className="contact-us-contact-ctn tariff-box" style={{height:'60px'}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:'19px'}}>Two Way Sedan</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><span className="tariff-text-1" style={{fontSize:'16px'}}>₹ 12/km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Two Way Minimum 250 km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><div>
     <Button onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }} style={{height:'44px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'18px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div></div>
       <div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'20px'}}>
       <div className="contact-us-contact-new-mobile" style={{height:'400px'}}>
     <div className="contact-us-contact-ctn tariff-box" style={{height:'60px'}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:'19px'}}>Two Way SUV</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><span className="tariff-text-1" style={{fontSize:'16px'}}>₹ 17/km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Two Way Minimum 250 km</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Driver Beta ₹ 400</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Rs. 500 Driver Beta 500 kms</span></div>
     <div className="contact-us-contact-ctn" style={{height:'60px'}}><span className="tariff-text-2" style={{fontSize:'16px'}}>Actual toll/permit charges</span></div>
     <div className="contact-us-contact-ctn tariff-box-1" style={{height:'60px'}}><div>
     <Button  
     onClick={()=>{
      history('/',{state:{showForm:Math.random()*73439493}})
     }}
     style={{height:'44px',width:'190px',borderRadius:'5px',
     marginTop:'14px',
     marginBottom:'14px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'18px',fontWeight:'bold'}} className="join-us-home-btn">Book Now</Button>
       </div></div>
       </div>
</div>
</MobileView>
{/* <div style={{textAlign:'center',marginTop:'16px',fontSize:'20px',textDecorationLine:'underline',textDecorationColor:"#f4c015"}}><span style={{fontWeight:'bolder'}}>Note:</span> All the mentioned tariff rates are subject to  change based on the fuel rates and govt regulations.</div> */}
  <div className="header-title-car" style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'50px',fontSize:isMobile?"20px":"30px"}}>Local Package Trips
</div>
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'30px'}}>
 <BrowserView style={{width:'90%'}}>
      
<div className="table-container" style={{width:'100%'}}>
      <table>
        <tbody>
          {localPackage.map((cell, index) => {
            if (index % 1 === 0) {
              console.log('asnjdajsbdja',listService.length);
              return (index !=0? <tr key={index}>
                
                <td className="table-cell" style={{height:isMobile?"60px":"80px",fontSize:isMobile?"14px":"22px"}}>  {cell.name}</td>
                 <td  className="table-cell" style={{height:isMobile?"60px":"80px",fontSize:isMobile?"14px":"22px"}}>  {cell.seran}</td>
                 <td  className="table-cell" style={{height:isMobile?"60px":"80px",fontSize:isMobile?"14px":"22px"}}>  {cell.muv}</td>
                 
               
             </tr>: <tr  key={index} 
             style={{padding:'0px',height:isMobile?"60px":"90px"}}
            //  style={{backgroundColor:'rgb(244, 192, 21)'}}
             >
            
                <td className="table-cell" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:isMobile?"16px":"25px"}}>{cell.name}</span></div> </td>
                <td className="table-cell" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text"  style={{fontSize:isMobile?"16px":"25px"}}>{cell.name_one}</span></div> </td>
                <td className="table-cell" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text"  style={{fontSize:isMobile?"16px":"25px"}}>{cell.name_two}</span></div> </td>
                 {/* <td  className="table-cell">  {cell.name_one}</td>
                 <td  className="table-cell">  {cell.name_two}</td> */}
                 
               
             </tr>
             
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
 </BrowserView>

 <MobileView style={{width:'100%',paddingLeft:'20px',paddingRight:'20px'}}>
       
<div className="table-container" style={{width:'100%'}}>
      <table>
        <tbody>
          {localPackage.map((cell, index) => {
            if (index % 1 === 0) {
              console.log('asnjdajsbdja',listService.length);
              return (index !=0? <tr key={index}>
                
                <td className="table-cell-mobile" style={{height:isMobile?"60px":"90px",fontSize:isMobile?"14px":"25px"}}>  {cell.name}</td>
                 <td  className="table-cell-mobile" style={{height:isMobile?"60px":"90px",fontSize:isMobile?"14px":"25px"}}>  {cell.seran}</td>
                 <td  className="table-cell-mobile" style={{height:isMobile?"60px":"90px",fontSize:isMobile?"14px":"25px"}}>  {cell.muv}</td>
                 
               
             </tr>: <tr  key={index} 
             style={{padding:'0px',height:isMobile?"60px":"90px"}}
            //  style={{backgroundColor:'rgb(244, 192, 21)'}}
             >
            
                <td className="table-cell-mobile" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text" style={{fontSize:isMobile?"16px":"25px"}}>{cell.name}</span></div> </td>
                <td className="table-cell-mobile" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text"  style={{fontSize:isMobile?"16px":"25px"}}>{cell.name_one}</span></div> </td>
                <td className="table-cell-mobile" style={{padding:'0px'}}>  <div className="contact-us-contact-ctn tariff-box" style={{height:isMobile?"60px":"90px"}}><span className="contact-us-contact-ctn-text tariff-text"  style={{fontSize:isMobile?"16px":"25px"}}>{cell.name_two}</span></div> </td>
                 {/* <td  className="table-cell">  {cell.name_one}</td>
                 <td  className="table-cell">  {cell.name_two}</td> */}
                 
               
             </tr>
             
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
 </MobileView>
    </div>
<BrowserView>
<div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'90px'}}>
<div className="table-container" style={{width:'90%'}}>
      <table>
        <tbody>
          {listService.map((cell, index) => {
            if (index % 5 === 0) {
              console.log('asnjdajsbdja',listService.length);
              return (
                <tr key={index}>
                  {listService.slice(index, index + 5).map((cell, subIndex) => (
                    <td key={subIndex} className="table-cell">  {cell}</td>
                  ))}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
    </div></BrowserView>

    <MobileView>
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'40px'}}>
<div className="table-container" style={{width:'90%'}}>
      <table>
        <tbody>
          {listService.map((cell, index) => {
            if (index % 2 === 0) {
              console.log('asnjdajsbdja',listService.length);
              return (
                <tr key={index} style={{height:isMobile?"40px":"90px",fontSize:isMobile?"13px":"25px"}}>
                  {listService.slice(index, index + 2).map((cell, subIndex) => (
                    <td key={subIndex} className="table-cell-mobile" style={{height:isMobile?"50px":"90px",fontSize:isMobile?"13px":"25px"}}>  {cell}</td>
                  ))}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
    </div>
    </MobileView>
  
    <div className="traffic-container-join-us" style={{marginBottom:'23px'}}>
<div style={{width:'100%',marginTop:'40px',paddingLeft:isMobile==true?"7%" :'18%'}}>
<div className="header-title-car" style={{textAlign:'left',fontSize:isMobile?"17px":'28px',fontWeight:'bold',color:'#f4c015'}}>FOR DRIVERS</div>
        <div className="header-title-car" style={{textAlign:'left',fontSize:isMobile?"19px":'48px',fontWeight:'bold'}}>DO YOU WANT TO EARN WITH US?</div>
        <div className="our-service-title-des">Are you an experienced driver willing to enrol with Budget Taxi? Come join us for these great benefits!</div>
        <div style={{display:'flex',marginTop:'30px'}}>
          <div style={{marginRight:'90px'}}>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              Quick Payments
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              Low Commissions
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              Stable Orders
              </div>
            </div>

          </div>
          <div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              Flexible Timings
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              Friendly Management
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text" style={{fontSize:isMobile?"13px":"16px"}}>
              24/7 Support
              </div>
            </div>

          </div>
        </div>
        <div style={{marginTop:'30px'}}>
        <Button  style={{height:isMobile?"44px":'55px',width: isMobile?"130px":'190px',borderRadius:'90px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:isMobile?"15px":'20px',fontWeight:'bold'}} className="join-us-home-btn">Join Us</Button>
        </div>
        </div>
        
        
        </div>
         
       <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
     
       <div className="contact-us-contact-new12" style={{width:isMobile?"100%":'70%',borderRadius:'9px'}}>
       <div  style={{backgroundImage: `url(${require('../../assets/229.jpg')})`,}} className="background-taff"/>
        <div style={{width:'100%',display:'flex',marginTop:'30px',flexDirection:'column',alignItems:'center',marginBottom:'30px',
        // position:'relative',
        
        // backgroundColor:"#fff"
      }}>
      

        <div >
          
        <div className="header-title-car" style={{textAlign:'center',fontSize:isMobile?"19px":"30px"}}>Booking Terms & Conditions
</div>
<div>

<div className="our-service-title-des" style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>
Tariff are subjected to change without prior notice.</div>
<div className="our-service-title-des" style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Vehicle are subjected to availability.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Time & Kms will start from the customer's point.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Passenger has to check driver name and ID number before boarding to the cab.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>All the mentioned tariff rates are subject to  change based on the fuel rates and govt regulations.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>If ideal waiting is more than two hours and exceeds 30 kms,will be converted to 4Hour package (Indica / Indigo / Logan / Swift / Etios).</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>If ideal waiting is more than one hours and exceeds 40 kms,will be converted to 5Hour package (Tavera / Innova / Xylo).</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Round Trips -1 day means 1 calender day (from 00:00 hrs to 23:59 hrs)</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Hill station charges - Rs.300 for sedan Rs.500 for MUV.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Washing Charges for carrying Pet Rs.400 for Sedan & Rs.500 for MUV.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>The Company and its drivers have the right to refuse to carry any passenger who is thought to be under the influence of alcohol or drugs and whose behavior poses a threat to the driver, the vehicle or any other passenger.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Every effort will be made by the company to ensure that vehicle(s) arrive on time and reach destinations on time. The company shall not be held responsible or liable for any delays (and/or arising consequential losses) that occur beyond its control.</div><div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Actual Toll Charges and permits will be charged as applicable</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Incase of any complaint about driver or his service, passenger can contact to Guest Care </div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Although we will take care, we will not be responsible for any loss or damage to luggage or personal property left in the vehicle or after the hire period.</div>
<div className="our-service-title-des" 
style={{paddingLeft:'40px',paddingRight:'50px',lineHeight:isMobile?"20px":'25px',fontSize:isMobile?"14px":'17px'}}>Vehicles are serviced and maintained to the highest levels however in the unfortunate event of breakdown Budget Taxi cannot be held responsible.
Cancellation need to be informed 1 day prior.</div>

</div>
</div>
   
</div>

        </div>
       </div>
          <div style={{}}>
          <Footer />
          </div>
          <div class="floating-icon">
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <IoLogoWhatsapp onClick={()=>{
window.open("https://Wa.me/+918825775128", '_blank').focus();
    }} size={40} color="#128C7E"/>
    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaInstagramSquare onClick={()=>{
      window.open("https://www.instagram.com/budget_.taxi?utm_source=qr&igsh=MWFnNGtmM2xteGZxdg==", '_blank').focus();
    }} size={40} color="#E1306C"/>

    </div>

    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaFacebookSquare onClick={()=>{
      window.open("https://www.facebook.com/profile.php?id=61557460117261&mibextid=ZbWKwL", '_blank').focus();
    }} size={40} color="#4267B2" />


    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
      <Image onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=customercare@budget-taxi.com&tf=1", '_blank').focus();
    }} src={require('../../assets/gmail.png')} style={{width:'45px',height:'38px'}} preview={false}/>
    {/* <IoMdMail onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com&tf=1", '_blank').focus();
    }} size={40} color="#ea4335" /> */}


    </div>

</div>
       </div>
       
   
}

export default TraiffScreen