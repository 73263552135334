import { useEffect, useState } from "react";
import Header from "../header";
import { Button, DatePicker, Image, Input, Select, notification } from "antd";
import { FaCheck, FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMdMail } from "react-icons/io";

import Footer from "../footer";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
let timeout
let scroll = 0

const stateList = [
  "Alandur", 
"Ambattur", 
"Aminjikarai", 
"Ayanavaram", 
"Egmore", 
"Guindy", 
"Madhavaram", 
"Madhuravoyal", 
"Mambalam", 
"Mylapore", 
"Perambur", 
"Purasavakkam", 
"Sholinganallur", 
"Thiruvottriyur", 
"Tondiarpet", 
"Velacherry", 
"Aanaimalai", 
"Annur", 
"Coimbatore(North)", 
"Coimbatore(South)", 
"Kinathukadavu", 
"Madukarai", 
"Mettupalayam", 
"Nagarkovil",
"Perur", 
"Pollachi", 
"Sulur", 
"Valparai", 
"Cuddalore", 
"Bhuvanagiri", 
"Chidambaram", 
"Kattumannarkoil", 
"Kurinjipadi", 
"Panruti", 
"Srimushnam", 
"Thittakudi", 
"Veppur", 
"Virudhachalam", 
"Dharmapuri", 
"Harur", 
"Karimangalam", 
"Nallampalli", 
"Palacode", 
"Pappireddipatti", 
"Pennagaram", 
"Atthur", 
"Dindigul (East)", 
"Dindigul (West)", 
"Guziliyamparai", 
"Kodaikanal", 
"Natham", 
"Nilakottai", 
"Oddanchatram", 
"Palani", 
"Vedasandur", 
"Erode", 
"Anthiyur", 
"Bhavani", 
"Gobichettipalayam", 
"Kodumudi", 
"Modakurichi", 
"Nambiyur", 
"Perundurai", 
"Sathiyamangalam", 
"Thalavadi", 
"Kancheepuram", 
"Kundrathur", 
"Sriperumbudur", 
"Uthiramerur", 
"Walajabad", 
"Agasteeswaram", 
"Kalkulam", 
"Killiyur", 
"Thiruvatar", 
"Thovalai", 
"Vilavankodu", 
"Karur", 
"Aravakurichi", 
"Kadavur", 
"Krishnarayapuram", 
"Kulithalai", 
"Manmangalam", 
"Pugalur", 
"Kallikudi", 
"Madurai (East)", 
"Madurai (North)", 
"Madurai (South)", 
"Madurai (West)", 
"Melur", 
"Peraiyur", 
"Thirumangalam", 
"Thiruparankundram", 
"Usilampatti", 
"Vadipatti", 
"Nagapattinam", 
"Kilvelur", 
"Thirukkuvalai", 
"Vedaranyam", 
"Namakkal", 
"Kholli Hills", 
"Kumarapalayam", 
"Mohanoor", 
"Paramathi Velur", 
"Rasipuram", 
"Senthamangalam", 
"Tiruchengode", 
"Udagamandalam", 
"Coonoor", 
"Gudalur", 
"Kothagiri", 
"Kundah", 
"Pandalur", 
"Perambalur", 
"Alathur", 
"Kunnam", 
"Veppanthattai", 
"Pudukottai", 
"Alangudi", 
"Aranthangi", 
"Avudiyarkoil", 
"Gandarvakottai", 
"Iluppur", 
"Karambakudi", 
"Kulathur", 
"Manamelkudi", 
"Ponnamaravathi", 
"Thirumayam", 
"Viralimalai", 
"Ramanathapuram", 
"Kadaladi", 
"Kamuthi", 
"Kezhakarai", 
"Mudukulathur", 
"Paramakudi", 
"Rajasingamangalam", 
"Rameswaram", 
"Tiruvadanai", 
"Salem", 
"Attur", 
"Edapadi", 
"Gangavalli", 
"Kadaiyampatti", 
"Mettur", 
"Omalur", 
"Pethanayakanpalayam", 
"Salem South", 
"Salem West", 
"Sankari", 
"Vazhapadi", 
"Yercaud", 
"Sivagangai", 
"Devakottai", 
"Ilayankudi", 
"Kalaiyarkovil", 
"Karaikudi", 
"Manamadurai", 
"Singampunari", 
"Thirupuvanam", 
"Tirupathur", 
"Thanjavur", 
"Boothalur", 
"Kumbakonam", 
"Orathanadu", 
"Papanasam", 
"Pattukottai", 
"Peravurani", 
"Thiruvaiyaru", 
"Thiruvidaimaruthur", 
"Theni", 
"Aandipatti", 
"Bodinayakanur", 
"Periyakulam", 
"Uthamapalayam", 
"Thiruvallur", 
"Avadi", 
"Gummidipoondi", 
"Pallipattu", 
"Ponneri", 
"Poonamallee", 
"R.K. Pet", 
"Tiruthani", 
"Uthukottai", 
"Thiruvannamalai", 
"Arni", 
"Chengam", 
"Chetpet", 
"Cheyyar", 
"Jamunamarathur", 
"Kalasapakkam", 
"Kilpennathur", 
"Polur", 
"Thandramet", 
"Vandavasi", 
"Vembakkam", 
"Thiruvarur", 
"Kodavasal", 
"Koothanallur", 
"Mannargudi", 
"Nannilam", 
"Needamangalam", 
"Thiruthuraipoondi", 
"Valangaiman", 
"Thoothukudi", 
"Eral", 
"Ettayapuram", 
"Kayathar", 
"Kovilpatti", 
"Ottapidaram", 
"Sattankulam", 
"Srivaikundam", 
"Tiruchendur", 
"Vilathikulam", 
"Lalgudi", 
"Manachanallur", 
"Manapparai", 
"Marungapuri", 
"Musiri", 
"Srirangam", 
"Thottiam", 
"Thuraiyur", 
"Tiruchirapalli (West)", 
"Tiruchirappalli (East)", 
"Tiruverumbur", 
"Tirunelveli", 
"Ambasamudram", 
"Cheranmahadevi", 
"Manur", 
"Nanguneri", 
"Palayamkottai", 
"Radhapuram", 
"Thisayanvilai", 
"Vellore", 
"Aanikattu", 
"Gudiyatham", 
"K V Kuppam", 
"Katpadi", 
"Pernambut", 
"Villupuram", 
"Gingee", 
"Kandachipuram", 
"Marakanam", 
"Melmalaiyanur", 
"Thiruvennainallur", 
"Tindivanam", 
"Vanur", 
"Vikravandi", 
"Virudhunagar", 
"Aruppukottai", 
"Kariyapatti", 
"Rajapalayam", 
"Sathur", 
"Sivakasi", 
"Srivilliputhur", 
"Tiruchuli", 
"Vembakottai", 
"Watrap", 
"Ariyalur", 
"Andimadam", 
"Sendurai", 
"Udaiyarpalayam", 
"Krishnagiri", 
"Anjetty", 
"Bargur", 
"Hosur", 
"Pochampalli", 
"Sulagiri", 
"Thenkanikottai", 
"Uthangarai", 
"Avinashi", 
"Dharapuram", 
"Kangeyam", 
"Madathukkulam", 
"Oothukuli", 
"Palladam", 
"Tiruppur (North)", 
"Tiruppur (South)", 
"Udumalaipettai", 
"Chengalpattu", 
"Cheyyur", 
"Maduranthakam", 
"Pallavaram", 
"Tambaram", 
"Thirukalukundram", 
"Tiruporur", 
"Vandalur", 
"Kallakurichi", 
"Chinnaselam", 
"Kalvarayan Hills", 
"Sankarapuram", 
"Tirukoilur", 
"Ulundurpet", 
"Arakkonam", 
"Arcot", 
"Kalavai", 
"Nemili", 
"Sholingur", 
"Walajah", 
"Tenkasi", 
"Alangulam", 
"Kadayanallur", 
"Sankarankovil", 
"Shenkottai", 
"Sivagiri", 
"Thiruvengadam", 
"Veerakeralampudur", 
"Tirupathur", 
"Ambur", 
"Natrampalli", 
"Vaniyambadi", 
"Mayiladuthurai", 
"Kuthalam", 
"Sirkali", 
"Tharangambadi", 
"Bangalore", 
"Trivandrum", 
"Thirupathy", 
"Hosur", 
"Nellore", 
"Ranipet", 
"Tenkasi", 
"Chennai", 
"Coimbatore", 
"Cuddalore", 
"Dharmapuri", 
"Dindigul", 
"Erode", 
"Kancheepuram", 
"Kanniyakumari", 
"Karur", 
"Madurai", 
"Nagapattinam", 
"Namakkal", 
"The Nilgiris (Udagamandalam)", 
"Perambalur", 
"Pudukottai", 
"Ramanathapuram", 
"Salem", 
"Sivagangai", 
"Thanjavur", 
"Theni", 
"Thiruvallur", 
"Thiruvannamalai", 
"Thiruvarur", 
"Thoothukudi", 
"Tiruchirappalli", 
"Tirunelveli", 
"Vellore", 
"Villupuram", 
"Virudhunagar", 
"Ariyalur", 
"Krishnagiri", 
"Tiruppur", 
"Chengalpattu", 
"Kallakurichi", 
"Ranipet", 
"Tenkasi", 
"Tirupathur", 
"Mayiladuthurai", 
"Pondichery", 
"Karaikal"
]
const servicePlaces= [{
  name:"Mayiladuthurai",location:"Mayiladuthurai"
},
{
  name:"Pondicherry",location:"Pondicherry"
},
{
  name:"Thiruvarur",location:"Thiruvarur"
},
{
  name:"Chidambaram",location:"Chidambaram"
},
{
  name:"Cuddalore",location:"Cuddalore"
},
{
  name:"Vellankanni",location:"Vellankanni"
},
{
  name:"Salem",location:"Salem"
},
{
  name:"Madurai",location:"Madurai"
},
{
  name:"Coimbatore",location:"Coimbatore"
},{

name:"Chennai",location:"Chennai"
},

]
const HomeScreen = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [cityList, setcityList] = useState(stateList)
  const [storeState, setstoreState] = useState({
    type:"",
    name:"",
    from:"",
    to:"",
    number:"",
    date:"",
    email:"",
    remark:""
  });
  const images = [
    require('../../assets/new2.jpg'),
    // require('../../assets/new2.jpg'),
    // require('../../assets/new3.jpg'),
    // require('../../assets/img7.png')
    // Add more image URLs as needed
  ];
  const images1 = [
    require('../../assets/new8.png'),
    require('../../assets/new2.jpg'),
    require('../../assets/new3.jpg'),
    require('../../assets/img7.png')
    // Add more image URLs as needed
  ];
  const taxiImage = [
    { name: 'MINI', image: require('../../assets/indica.png') },
    { name: 'SEDAN', image: require('../../assets/nissansunny.png') },
    { name: 'MUV', image: require('../../assets/tavera.png') },
    { name: 'MUV PRIME', image: require('../../assets/xylo.png') },
    { name: 'SUV', image: require('../../assets/innova.png') },
    // { name: 'TEMPO', image: require('../../assets/tempo.png') },
  ]
  const [fromItem, setfromItem] = useState('')
  const [toItems, settoItems] = useState('')
const [showForm, setshowForm] = useState(false)
  const [carImageSelect, setcarImageSelect] = useState(0)
let routes = useLocation()
console.log('andasndmnasmdas',routes);

useEffect(() => {
  console.log('asdnkanskdas',routes.state?.showForm);
  if(routes.state?.showForm){
    setshowForm(true)
  }else{
    setshowForm(false)
  }
}, [routes.state?.showForm])

  useEffect(() => {
    window.onscroll = () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        if (scroll >= window.scrollY && window.scrollY > 10) {
          document.getElementById('header').classList.add('sticky')
        } else {
          document.getElementById('header').classList.remove('sticky')
        }

        scroll = window.scrollY
      }, 10)
    }
  }, [])

  useEffect(() => {
    let imageDetails = showForm==true?images:images1

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageDetails.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };
  
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const generateLableValue =(data,stateName)=>{
let a =[]
cityList.map((value)=> {
  if(stateName && value!=stateName){
    a.push({value: value,label: value,})
  }else if(!stateName){
    a.push({value: value,label: value,})
  }

})

return a




    }
    const sendMessage = () => {
      const phoneNumber = '+918825775128'; // Replace with your phone number
      const message = encodeURIComponent(`Car Type:${taxiImage[carImageSelect].name}\n Trip:${storeState.type}\n Name:${storeState.name}\n From: ${storeState.from}\n To: ${storeState.to}\n Number:${storeState.number}\n Date:${storeState.date}\n Email:${storeState.email}\n Remark:${storeState.remark}\n`);
    
      // const message = 'First line\nSecond line\nThird line'
      // window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank').focus();
    };
    let imageDetails = showForm==true?images:images1
    let imageDetailsClass = showForm==true?"background1":`background`
  return <div>
    <Header onChangeHeader ={()=>{
setshowForm(true)
    }} />
    <div>
      <div className="background-container" style={{height:isMobile==true?showForm==true? "780px":"370px":"820px"}}>
        {<MobileView>
          {imageDetails.map((image, index) => (
          <div
            key={index}
            className={`${imageDetailsClass} ${index === currentImageIndex ? 'active' : ''}`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        ))}</MobileView>}

{<BrowserView>
          {imageDetails.map((image, index) => (
          <div
            key={index}
            className={`${imageDetailsClass} ${index === currentImageIndex ? 'active' : ''}`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        ))}</BrowserView>}
        {showForm==true &&<>
        <MobileView>
        <div className="container-form-mobile" style={{ flexDirection: 'column', padding: '10px' }}>
          <div className="header-title-car" style={{fontSize:'21px'}}>Get Taxi Online</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '25px' }} className="inside-image-container-mobile">
            {taxiImage.map((value, index) => {
              return <div onClick={()=>{
                setcarImageSelect(index)
              }} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '95px', height: '70px',backgroundColor: index==carImageSelect? '#ffffff':'transparent' }} className="hover-container-car">

                <div key={index} className="background-container-inside"> <div style={{
                  backgroundImage: `url(${value.image})`,
                  width: '80px', height: '72px'
                }} className="background-inside" />

                  {/* <Image preview={false} src={value.image}  style={{width:'90px',height:'52'}}/> */}

                </div> <div className="inside-car-title">{value.name}
                </div></div>
            })}

          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: '24px', flexDirection: 'column' }}>

            {/* <div style={{ width: '100%', display: 'flex', alignItems: 'center',
            justifyContent:'center'
           }}><Input placeholder="Name" style={{ height: '45px', width: '60%' }} /></div> */}
           <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
           <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Select
                style={{width:'95%',height:'40px'}}
                  showSearch
                  placeholder="Round/One way"
                  optionFilterProp="children"
                  onChange={(value)=>{setfromItem(value);
                 setstoreState({...storeState,type:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={[{value: 'Round Trip',label: 'Round Trip',},
                  {value: 'Oneway Trip',label: 'Oneway Trip',}]}
                />

              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Input onChange={(e)=>{
                 setstoreState({...storeState,name:e.target.value})
              }} placeholder="Name" style={{ height: '40px', width: '90%' }} />
              
</div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Select
                style={{width:'95%',height:'40px'}}
                  showSearch
                  placeholder="From"
                  optionFilterProp="children"
                  onChange={(value)=>{setfromItem(value);
                      setstoreState({...storeState,from:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={generateLableValue(cityList,toItems)}
                />

              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
              <Select
              style={{width:'95%',height:'40px'}}
                  showSearch
                  placeholder="To"
                  optionFilterProp="children"
                  onChange={(value)=>{settoItems(value);
                    setstoreState({...storeState,to:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={generateLableValue(cityList,fromItem)}
                />
</div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Mobile No" 
              onChange={(e)=>{
                setstoreState({...storeState,number:e.target.value})
              }}
              style={{ height: '40px', width: '95%' }} />
              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <DatePicker format="YYYY-MM-DD hh:mm A" showTime={true} onChange={(date,datestring)=>{
 setstoreState({...storeState,date:datestring})
              }} picker="date" style={{width:'90%',height:'45px'}} /> 
              
</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Email (Optional)" 
              onChange={(e)=>{
                setstoreState({...storeState,email:e.target.value})
              }}
              style={{ height: '40px', width: '98%' }} />
              </div>
            
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Remarks" 
              onChange={(e)=>{
                setstoreState({...storeState,remark:e.target.value})
              }}
              style={{ height: '40px', width: '100%' }} />
              </div>
            
            </div>

            <div style={{display:'flex',justifyContent:'center',marginTop:'24px'}}>
            <Button onClick={()=>{
              notification.success({
                message:"Success",
                description:"Thank you for Choosing  Budget-Taxi we will contact you for the booking confirmation"
              })
              setTimeout(() => {
                sendMessage()
              }, 1000);
            }} size={'large'} className="get-tax-button-mobile">
            Get Taxi
          </Button>
            </div>



          </div>
        </div>
        </MobileView>
        <BrowserView style={{marginTop:'10px'}}>
        <div className="container-form" style={{ flexDirection: 'column', padding: '24px' }}>
          <div className="header-title-car">Get Taxi Online</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '25px' }} className="inside-image-container">
            {taxiImage.map((value, index) => {
              return <div onClick={()=>{
                setcarImageSelect(index)
              }} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '115px', height: '75px',backgroundColor: index==carImageSelect? '#ffffff':'transparent' }} className="hover-container-car">

                <div key={index} className="background-container-inside"> <div style={{
                  backgroundImage: `url(${value.image})`,
                  width: '80px', height: '72px'
                }} className="background-inside" />

                  {/* <Image preview={false} src={value.image}  style={{width:'90px',height:'52'}}/> */}

                </div> <div className="inside-car-title">{value.name}
                </div></div>
            })}

          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: '24px', flexDirection: 'column' }}>

            {/* <div style={{ width: '100%', display: 'flex', alignItems: 'center',
            justifyContent:'center'
           }}><Input placeholder="Name" style={{ height: '45px', width: '60%' }} /></div> */}
           <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
           <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Select
                style={{width:'90%',height:'45px'}}
                  showSearch
                  placeholder="Round/One way"
                  optionFilterProp="children"
                  onChange={(value)=>{setfromItem(value);
                 setstoreState({...storeState,type:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={[{value: 'Round Trip',label: 'Round Trip',},
                  {value: 'Oneway Trip',label: 'Oneway Trip',}]}
                />

              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Input onChange={(e)=>{
                 setstoreState({...storeState,name:e.target.value})
              }} placeholder="Name" style={{ height: '45px', width: '90%' }} />
              
</div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Select
                style={{width:'90%',height:'45px'}}
                  showSearch
                  placeholder="From"
                  optionFilterProp="children"
                  onChange={(value)=>{setfromItem(value);
                      setstoreState({...storeState,from:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={generateLableValue(cityList,toItems)}
                />

              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
              <Select
              style={{width:'90%',height:'45px'}}
                  showSearch
                  placeholder="To"
                  optionFilterProp="children"
                  onChange={(value)=>{settoItems(value);
                    setstoreState({...storeState,to:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={generateLableValue(cityList,fromItem)}
                />
</div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Mobile No" 
              onChange={(e)=>{
                setstoreState({...storeState,number:e.target.value})
              }}
              style={{ height: '45px', width: '90%' }} />
              </div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <DatePicker format="YYYY-MM-DD hh:mm A" showTime={true} onChange={(date,datestring)=>{
 setstoreState({...storeState,date:datestring})
              }} picker="date" style={{width:'90%',height:'45px'}} /> 
              
</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Email (Optional)" 
              onChange={(e)=>{
                setstoreState({...storeState,email:e.target.value})
              }}
              style={{ height: '45px', width: '98%' }} />
              </div>
            
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="Remarks" 
              onChange={(e)=>{
                setstoreState({...storeState,remark:e.target.value})
              }}
              style={{ height: '45px', width: '98%' }} />
              </div>
            
            </div>

            <div style={{display:'flex',justifyContent:'center',marginTop:'24px'}}>
            <Button onClick={()=>{
              notification.success({
                message:"Success",
                description:"Thank you for Choosing  Budget-Taxi we will contact you for the booking confirmation"
              })
              setTimeout(() => {
                sendMessage()
              }, 1000);
            }} size={'large'} className="get-tax-button">
            Get Taxi
          </Button>
            </div>



          </div>
        </div></BrowserView>
        </>}
       
        {/* Your content here */}
      </div>
      <MobileView>
      <div className="service-container" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
     

     <div style={{width:'100%',marginTop:'40px'}}>
      <div className="header-title-car" style={{textAlign:'center',fontSize:'22px'}}>SERVICES AVAILABE AT</div>
      <div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'30px'}}>
        <div style={{display: 'flex', flexWrap: 'wrap',justifyContent:'center',alignItems:'center',width:'90%'}}>
          {servicePlaces.map((value,index)=>{
           return <div key={index} className="service-name-style-mobile">
              <div className="get-tax-button-name-mobile">{value.name}</div>

            </div>
          })}

        </div>
      </div>
     </div>

</div>
      </MobileView>
      <BrowserView>
      <div className="service-container" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
     

       <div style={{width:'100%',marginTop:'40px'}}>
        <div className="header-title-car" style={{textAlign:'center'}}>SERVICES AVAILABE AT</div>
        <div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'30px'}}>
          <div style={{display: 'flex', flexWrap: 'wrap',justifyContent:'center',alignItems:'center',width:'60%'}}>
            {servicePlaces.map((value,index)=>{
             return <div key={index} className="service-name-style ">
                <div className="get-tax-button-name">{value.name}</div>

              </div>
            })}

          </div>
        </div>
       </div>

</div></BrowserView>

<div className="our-service-ctn">
<div style={{width:'100%',marginTop:'40px'}}>
<div className="header-title-car" style={{textAlign:'center',fontSize:isMobile?'22px':'28px',fontWeight:'bold',color:'#f4c015'}}>WELCOME</div>
        <div className="header-title-car" style={{textAlign:'center',fontSize:isMobile?'24px':'48px',fontWeight:'bold'}}>OUR SERVICES</div>

        <BrowserView>
        <div style={{display:'flex',justifyContent:'center'}}>

        <div className="our-service-ctn2">
            <div><img src={require('../../assets/_services-1.png')} style={{height:'71px',width:'71px'}}/> </div>
            <div className="our-service-title">
            RAPID CITY TRANSFER
            </div>
            <div className="our-service-title-des">We will bring you quickly and comfortably to anywhere in your city  </div>

          </div>
          <div className="our-service-ctn2">
            <div><img src={require('../../assets/_services-2.png')} style={{height:'71px',width:'71px'}}/> </div>
            <div className="our-service-title">
            HOTEL PICKUP
            </div>
            <div className="our-service-title-des">Visiting the city? We are here at your service for pick up and drop at your hotel</div>

          </div>

          <div className="our-service-ctn2">
            <div><img src={require('../../assets/_services-3.png')} style={{height:'71px',width:'71px'}}/> </div>
            <div className="our-service-title">
            AIRPORT PICKUP/DROP
            </div>
            <div className="our-service-title-des">Contact us for Airport pick up/drop for you or your guests</div>

          </div>

          <div className="our-service-ctn2">
            <div><img src={require('../../assets/_services-4.png')} style={{height:'71px',width:'71px'}}/> </div>
            <div className="our-service-title">
            BAGGAGE TRANSPORT
            </div>
            <div className="our-service-title-des">Have extra luggage? We will arrange the perfect cab with cabin space and carriers for your need</div>

          </div>


        </div>
        </BrowserView>

        <MobileView>
        <div style={{display:'flex',justifyContent:'space-around',width:'100%',paddingLeft:'20px'}}>

<div className="our-service-ctn2">
    <div><img src={require('../../assets/_services-1.png')} style={{height:'58px',width:'58px'}}/> </div>
    <div className="our-service-title" style={{fontSize:'15px'}}>
    RAPID CITY TRANSFER
    </div>
    <div className="our-service-title-des" style={{fontSize:'14px'}}>We will bring you quickly and comfortably to anywhere in your city  </div>

  </div>
  <div className="our-service-ctn2">
    <div><img src={require('../../assets/_services-2.png')} style={{height:'58px',width:'58px'}}/> </div>
    <div className="our-service-title" style={{fontSize:'15px'}}>
    HOTEL PICKUP
    </div>
    <div className="our-service-title-des" style={{fontSize:'14px'}}>Visiting the city? We are here at your service for pick up and drop at your hotel</div>

  </div>
  </div>
  <div style={{display:'flex',justifyContent:'space-around',width:'100%',paddingLeft:'20px',marginBottom:'20px'}}>

  <div className="our-service-ctn2">
    <div><img src={require('../../assets/_services-3.png')} style={{height:'58px',width:'58px'}}/> </div>
    <div className="our-service-title" style={{fontSize:'15px'}}>
    AIRPORT PICKUP/DROP
    </div>
    <div className="our-service-title-des" style={{fontSize:'14px'}}>Contact us for Airport pick up/drop for you or your guests</div>

  </div>

  <div className="our-service-ctn2">
    <div><img src={require('../../assets/_services-4.png')} style={{height:'58px',width:'58px'}}/> </div>
    <div className="our-service-title" style={{fontSize:'15px'}}>
    BAGGAGE TRANSPORT
    </div>
    <div className="our-service-title-des" style={{fontSize:'14px'}}>Have extra luggage? We will arrange the perfect cab with cabin space and carriers for your need</div>

  </div>


</div>
        </MobileView>
        </div>
</div>
<div className="traffic-container">
<div style={{width:'100%',marginTop:'40px'}}>
<div className="header-title-car" style={{textAlign:'center',fontSize:isMobile==true?"21px":'28px',fontWeight:'bold',color:'#f4c015'}}>SEE OUR</div>
        <div className="header-title-car" style={{textAlign:'center',fontSize:isMobile==true?"23px":'48px',fontWeight:'bold'}}>TARIFFS</div>
<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
<div style={{display:'flex',flexWrap:'wrap',width:isMobile==true?"95%": '75%',justifyContent:'center'}}>
  <div className="taffic-card-ctn" style={{padding:'20px',borderRadius:isMobile?"10px":"",height:isMobile?"380px":"445px"}}>
    <div>
      <img src={require('../../assets/indica.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>MINI</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 4+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>The perfect cab for a quick ride </div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Indica, Etios Liva, Wagon R, Alto, Celerio, Indigo, Bolt</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 11</div> <div >/km (Non-AC)</div></div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 12</div> <div >/km (AC)</div></div>
  </div>
  <div className="taffic-card-ctn" style={{padding:'20px',height:isMobile?"380px":"445px",borderRadius:isMobile?"10px":""}}>
    <div>
      <img src={require('../../assets/nissansunny.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>SEDAN</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 4+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Travel in style with the premium home cars.</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Swift, Etios, Ford Amaze, Sunny</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 12</div> <div >/km (Non-AC)</div></div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 13</div> <div >/km (AC)</div></div>
  </div>
  <div className="taffic-card-ctn" style={{padding:'20px',height:isMobile?"380px":"445px",borderRadius:isMobile?"10px":""}}>
    <div>
      <img src={require('../../assets/tavera.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>MUV</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 8+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>The people mover, for the joint family travel.</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Tavera, Sumo, Eco, Bolero</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 14</div> <div >/km (Non-AC)</div></div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 16</div> <div >/km (AC)</div></div>
  </div>
  <div className="taffic-card-ctn" style={{padding:'20px',height:isMobile?"380px":"445px",borderRadius:isMobile?"10px":""}}>
    <div>
      <img src={require('../../assets/xylo.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>MUV PRIME</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 7+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>The class comfy ride for the complete family.</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Xylo, Enjoy, Ertiga, Lodgy, Marazzo</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 15</div> <div >/km (Non-AC)</div></div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 17</div> <div >/km (AC)</div></div>
  </div>
  {/* <div className="taffic-card-ctn" style={{padding:'20px'}}>
    <div>
      <img src={require('../../assets/tempo.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>Tempo</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 12+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Spacious, Safe and Comfortable.</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Tempo Traveler</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div>Rs. 25</div> <div >/km</div></div>
  </div> */}
  <div className="taffic-card-ctn" style={{padding:'20px',height:isMobile?"380px":"445px",borderRadius:isMobile?"10px":""}}>
    <div>
      <img src={require('../../assets/innova.png')} style={{width:'172px'}}/>
    </div>
    <div className={isMobile?"taffic-card-ctn-title-mobile":"taffic-card-ctn-title"}>SUV</div>
    <div className={isMobile?"taffic-card-ctn-title-des-mobile":"taffic-card-ctn-title-des"}>Seating 7+1</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>When Luxury meets Comfort.</div>
    <div style={{fontSize:'14px',marginTop:'15px'}}>Innova Crysta</div>
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 17</div> <div >/km (Non-AC)</div></div>
    {/* <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div>Rs. 11</div> <div >/km (Non-AC)</div></div> */}
    <div className="taffic-card-ctn-price" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:isMobile?"20px":"26px"}}>
      <div>Rs. 19</div> <div >/km (AC)</div></div>
  </div>
</div></div>
        </div>
        
</div>
{/* <div className="traffic-container-join-us">
<div style={{width:'100%',marginTop:'40px',paddingLeft:'18%'}}>
<div className="header-title-car" style={{textAlign:'left',fontSize:'28px',fontWeight:'bold',color:'#f4c015'}}>FOR DRIVERS</div>
        <div className="header-title-car" style={{textAlign:'left',fontSize:'48px',fontWeight:'bold'}}>DO YOU WANT TO EARN WITH US?</div>
        <div className="our-service-title-des">Are you an experienced driver willing to enrol with Maya Call Taxi? Come join us for these great benefits!</div>
        <div style={{display:'flex',marginTop:'30px'}}>
          <div style={{marginRight:'90px'}}>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              Quick Payments
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              Low Commissions
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              Stable Orders
              </div>
            </div>

          </div>
          <div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              Flexible Timings
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              Friendly Management
              </div>
            </div>
            <div className="join-support-ctn">
              <div>
              <FaCheck color="#f4c015"/>
              </div>
              <div className="join-support-text">
              24/7 Support
              </div>
            </div>

          </div>
        </div>
        <div style={{marginTop:'30px'}}>
        <Button  style={{height:'55px',width:'190px',borderRadius:'90px',
        backgroundColor:'#f4c015',color:'#1f1f1f',fontSize:'20px',fontWeight:'bold'}} className="join-us-home-btn">Join Us</Button>
        </div>
        </div>
        
        
        </div> */}
    </div>
    <div style={{marginTop:'30px'}}>
    <Footer />
    </div>
    <div class="floating-icon">
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <IoLogoWhatsapp onClick={()=>{
window.open("https://Wa.me/+918825775128", '_blank').focus();
    }} size={40} color="#128C7E"/>
    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaInstagramSquare onClick={()=>{
      window.open("https://www.instagram.com/budget_.taxi?utm_source=qr&igsh=MWFnNGtmM2xteGZxdg==", '_blank').focus();
    }} size={40} color="#E1306C"/>

    </div>

    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaFacebookSquare onClick={()=>{
      window.open("https://www.facebook.com/profile.php?id=61557460117261&mibextid=ZbWKwL", '_blank').focus();
    }} size={40} color="#4267B2" />


    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
      <Image onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=customercare@budget-taxi.com&tf=1", '_blank').focus();
    }} src={require('../../assets/gmail.png')} style={{width:'45px',height:'38px'}} preview={false}/>
    {/* <IoMdMail onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com&tf=1", '_blank').focus();
    }} size={40} color="#ea4335" /> */}


    </div>

</div>
  </div>
}

export default HomeScreen;