import { Button, Image, Input, Select, notification } from "antd"
import Header from "../header"
import { useState } from "react"
import TextArea from "antd/es/input/TextArea"
import Footer from "../footer"
import { IoLogoWhatsapp } from "react-icons/io"
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"

import { BrowserView, MobileView } from "react-device-detect";

const stateList = [
       "Ariyalur",
       "Chennai",
       "Coimbatore",
       "Cuddalore",
       "Dharmapuri",
       "Dindigul",
       "Erode",
       "Kanchipuram",
       "Kanyakumari",
       "Karur",
       "Krishnagiri",
       "Madurai",
       "Nagapattinam",
       "Namakkal",
       "Nilgiris",
       "Perambalur",
       "Pudukkottai",
       "Ramanathapuram",
       "Salem",
       "Sivaganga",
       "Thanjavur",
       "Theni",
       "Thoothukudi (Tuticorin)",
       "Tiruchirappalli",
       "Tirunelveli",
       "Tiruppur",
       "Tiruvallur",
       "Tiruvannamalai",
       "Tiruvarur",
       "Vellore",
       "Viluppuram",
       "Virudhunagar"
     ]
const JoinUsScreen = () =>{
  const [storeState, setstoreState] = useState({
    name:"",
    email:"",
    number:"",
    location:"",
    registration:"",
    vehicle:"",
    message:""
  });
       const [cityList, setcityList] = useState(stateList)
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
const onSearch = (value) => {
       console.log('search:', value);
     };
const generateLableValue =(data,stateName)=>{
let a =[]
cityList.map((value)=> {
if(stateName && value!=stateName){
a.push({value: value,label: value,})
}else if(!stateName){
a.push({value: value,label: value,})
}

})

return a




}
const sendMessage = () => {
  const phoneNumber = '+918825775128'; // Replace with your phone number
  const message = encodeURIComponent(`Name:${storeState.name}\n  Number:${storeState.number}\n Email:${storeState.email}\n Location:${storeState.location}\n Registration No:${storeState.registration}\n Vehicle Type:${storeState.vehicle}\n Message:${storeState.message}\n`);

// const message = 'First line\nSecond line\nThird line'
// window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank').focus();
};
       return<> <div >
<Header enableStyle={true} />
{/* <div  style={{backgroundImage: `url(${require('../../assets/b1.jpg')})`,}} className="background-taff"/> */}
{/* <div className="joinus-header">
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}><div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Become a Driver</div>
</div>
<div className="header-joinus-title">
BECOME A DRIVER

</div>
</div> */}
 <BrowserView>
<div className="joinus-header">
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Become a Driver</div>
</div>
<div className="header-joinus-title">
BECOME A DRIVER

</div>
</div>

</BrowserView>
<MobileView>
<div className="joinus-header" style={{height:'100px'}}>
       <div style={{display:'flex',textAlign:'center',justifyContent:'center',fontSize:'19px'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Become a Driver</div>
</div>
<div className="header-joinus-title" style={{fontSize:'20px'}}>
BECOME A DRIVER

</div>
</div>
</MobileView>

<div>
       <div className="joinus-des">If you want to join with us with your car feel free to get in touch!</div>
       <div className="joinus-des" style={{marginTop:'20px'}}>We are available from 10 AM - 11 PM</div>
</div>
<div style={{width:'100%',justifyContent:'center',display:'flex'}}>

<div className="container-form-contact" style={{ flexDirection: 'column', padding: '24px' ,position:'relative'}}>
          
         
          <div style={{ display: 'flex', width: '100%', marginTop: '24px', flexDirection: 'column' }}>

          <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
              <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Name *</div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input onChange={(e)=>{setstoreState({...storeState,name: e.target.value})}} placeholder="" style={{ height: '45px', width: '90%' }} />
              </div>
              </div>
              <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Phone No *</div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="" style={{ height: '45px', width: '90%' }}  onChange={(e)=>{setstoreState({...storeState,number: e.target.value})}}/>
              </div>
              </div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
            <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Email *</div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input placeholder="" style={{ height: '45px', width: '90%' }}  onChange={(e)=>{setstoreState({...storeState,email: e.target.value})}}/>
              </div>
              </div>
            <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Location (City) *</div>
                     <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Select
                style={{width:'90%',height:'45px'}}
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(value)=>{
                   setstoreState({...storeState,location:value})
                  }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={generateLableValue(cityList,'')}
                />

              </div>
              </div>
            
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginTop:'24px'}}>
            
            <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Vehicle Registration No *</div>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
              <Input  onChange={(e)=>{setstoreState({...storeState,registration: e.target.value})}} placeholder="" style={{ height: '45px', width: '90%' }} />
              </div>
              </div>
            <div style={{width: '100%',}}>
                     <div className="joinus-form-des" >Your Vehicle Type *</div>
                     <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                     <Input  onChange={(e)=>{setstoreState({...storeState,vehicle: e.target.value})}} placeholder="" style={{ height: '45px', width: '90%' }} />

              </div>
              </div>
           
            </div>
            <div style={{width: '100%',marginTop:'20px'}}>
                     <div className="joinus-form-des" >Message</div>
                     <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                     <TextArea rows={7} placeholder="Message" maxLength={56} onChange={(e)=>{setstoreState({...storeState,message: e.target.value})}}/>
              </div>
              </div>

            <div style={{display:'flex',justifyContent:'center',marginTop:'24px'}}>
            <Button onClick={()=>{
             notification.success({
              message:"Success",
              description:"Your request has been submitted successfully we will contact you shortly"
            })
            setTimeout(() => {
              sendMessage()
            }, 1000);
            }} size={'large'} className="get-tax-button">
            Submit
          </Button>
            </div>



          </div>
          </div>
          </div>
          <div style={{marginTop:'30px'}}>
          <Footer />
          </div>
          <div class="floating-icon">
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <IoLogoWhatsapp onClick={()=>{
window.open("https://Wa.me/+918825775128", '_blank').focus();
    }} size={40} color="#128C7E"/>
    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaInstagramSquare onClick={()=>{
      window.open("https://www.instagram.com/budget_.taxi?utm_source=qr&igsh=MWFnNGtmM2xteGZxdg==", '_blank').focus();
    }} size={40} color="#E1306C"/>

    </div>

    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaFacebookSquare onClick={()=>{
      window.open("https://www.facebook.com/profile.php?id=61557460117261&mibextid=ZbWKwL", '_blank').focus();
    }} size={40} color="#4267B2" />


    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
      <Image onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=customercare@budget-taxi.com&tf=1", '_blank').focus();
    }} src={require('../../assets/gmail.png')} style={{width:'45px',height:'38px'}} preview={false}/>
    {/* <IoMdMail onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com&tf=1", '_blank').focus();
    }} size={40} color="#ea4335" /> */}


    </div>

</div>
       </div>
       
       </>
}

export default JoinUsScreen