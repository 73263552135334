import { BrowserView, MobileView } from "react-device-detect"
import { FaSquarePhone } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";

const Footer =()=>{

       return  <div className="footer-container">
<BrowserView>
<div style={{display:'flex',justifyContent:'center'}}>
       <div style={{width:'30%'}}>
              <div className="footer-title">ABOUT US</div>
<div className="footer-title-des" style={{width:'70%',marginTop:'20px'}}>Budget Taxi one of the emerging taxi services in tamilnadu we give best rides in low prices.If you are looking for ride with right fare we are here to give ride.</div>

{/* <div className="footer-title-des" style={{marginTop:'20px'}}>A Unit of Budget Taxi Groups</div> */}

       </div>

       <div style={{width:'10%'}}>
<div className="footer-title">EXPLORE</div>
<div className="footer-title-des" style={{marginTop:'20px'}}>
Contact Us
</div>
<div className="footer-title-des" style={{marginTop:'10px'}}>
Pay Now
</div>
<div className="footer-title-des" style={{marginTop:'10px'}}>
Join Us
</div>
       </div>
       <div style={{width:'30%'}}>
       <div className="footer-title">CONTACT US</div>
       
       <div className="footer-title-des" style={{marginTop:'20px'}}> Head Office</div>
       <div className="footer-title-des" style={{marginTop:'10px',width:'70%',lineHeight:'29px'}}>
Address: Mayiladuthurai/Chidambaram, Tamil Nadu 609001, India
</div>

<div className="footer-title-des" style={{marginTop:'10px',alignItems:'center',display:'flex'}}><FaSquarePhone style={{marginRight:'2px'}} />{' '} <span>{' '} +919894959128</span></div>
<div className="footer-title-des" style={{marginTop:'10px',alignItems:'center',display:'flex'}}><FaWhatsapp style={{marginRight:'2px'}}  /> <span>+918825775128</span></div>  
<div className="footer-title-des" style={{marginTop:'10px'}}><span>For Booking:</span> budgetttaxi@gmail.com</div>
<div className="footer-title-des" style={{marginTop:'10px'}}><span>For Enquiry:</span> Support@budget-taxi.com</div>
       </div>
</div></BrowserView>
<MobileView>
<div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',paddingLeft:'40px'}}>
       <div style={{width:'100%',}}>
              <div className="footer-title" style={{fontSize:'18px'}}>ABOUT US</div>
<div className="footer-title-des" style={{width:'95%',marginTop:'20px',fontSize:'13px'}}>Budget Taxi one of the emerging taxi services in tamilnadu we give best rides in low prices.If you are looking for ride with right fare we are here to give ride.</div>

{/* <div className="footer-title-des" style={{marginTop:'20px',fontSize:'13px'}}>A Unit of Budget Taxi Groups</div> */}

       </div>

       <div style={{width:'100%',marginTop:'20px'}}>
<div className="footer-title" style={{fontSize:'18px'}}>EXPLORE</div>
<div className="footer-title-des" style={{marginTop:'20px',fontSize:'13px'}}>
Contact Us
</div>
<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px'}}>
Pay Now
</div>
<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px'}}>
Join Us
</div>
       </div>
       <div style={{width:'100%',marginTop:'20px'}}>
       <div className="footer-title" style={{fontSize:'18px'}}>CONTACT US</div>
       
       <div className="footer-title-des" style={{marginTop:'20px',fontSize:'13px'}}> Head Office</div>
       <div className="footer-title-des" style={{marginTop:'10px',width:'70%',lineHeight:'29px',fontSize:'13px'}}>
       Address: Mayiladuthurai/Chidambaram, Tamil Nadu 609001, India
</div>

<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px',alignItems:'center',display:'flex'}}><FaSquarePhone style={{marginRight:'2px'}}/> <span>+919894959128</span></div> 
<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px',alignItems:'center',display:'flex'}}><FaWhatsapp style={{marginRight:'2px'}}/> <span>+918825775128</span></div> 

<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px'}}><span>For Booking:</span> budgetttaxi@gmail.com</div>
<div className="footer-title-des" style={{marginTop:'10px',fontSize:'13px'}}><span>For Enquiry:</span> Support@budget-taxi.com</div>
       </div>
</div>
</MobileView>
       </div>
}

export default Footer