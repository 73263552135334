import { Button, Image, Input, Select, notification } from "antd"
import Header from "../header"
import { useState } from "react"
import TextArea from "antd/es/input/TextArea"
import { FaLocationArrow } from "react-icons/fa";
import { FaPhoneSquareAlt, FaInstagramSquare, FaYoutube, FaFacebookSquare } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { FaSquarePhone, FaSquareWhatsapp } from "react-icons/fa6";
import Footer from "../footer";
import { BrowserView, MobileView } from "react-device-detect";


const stateList = [
       "Ariyalur",
       "Chennai",
       "Coimbatore",
       "Cuddalore",
       "Dharmapuri",
       "Dindigul",
       "Erode",
       "Kanchipuram",
       "Kanyakumari",
       "Karur",
       "Krishnagiri",
       "Madurai",
       "Nagapattinam",
       "Namakkal",
       "Nilgiris",
       "Perambalur",
       "Pudukkottai",
       "Ramanathapuram",
       "Salem",
       "Sivaganga",
       "Thanjavur",
       "Theni",
       "Thoothukudi (Tuticorin)",
       "Tiruchirappalli",
       "Tirunelveli",
       "Tiruppur",
       "Tiruvallur",
       "Tiruvannamalai",
       "Tiruvarur",
       "Vellore",
       "Viluppuram",
       "Virudhunagar"
]
const ContactUsScreen = () => {
       const [storeState, setstoreState] = useState({
              name:"",
              email:"",
              number:"",
              subject:"",
              message:""
            });
       const [cityList, setcityList] = useState(stateList)
       // Filter `option.label` match the user type `input`
       const filterOption = (input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
       const onSearch = (value) => {
              console.log('search:', value);
       };
       const generateLableValue = (data, stateName) => {
              let a = []
              cityList.map((value) => {
                     if (stateName && value != stateName) {
                            a.push({ value: value, label: value, })
                     } else if (!stateName) {
                            a.push({ value: value, label: value, })
                     }

              })

              return a




       }
       const sendMessage = () => {
              const phoneNumber = '+918825775128'; // Replace with your phone number
              const message = encodeURIComponent(`Name:${storeState.name}\n  Number:${storeState.number}\n Email:${storeState.email}\n Subject:${storeState.subject}\n Message:${storeState.message}\n`);
    
      // const message = 'First line\nSecond line\nThird line'
      // window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank').focus();
       };
       return <div>
              <Header enableStyle={true} />
              <BrowserView>
<div className="joinus-header">
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Contact</div>
</div>
<div className="header-joinus-title">
CONTACT

</div>
</div>

</BrowserView>
<MobileView>
<div className="joinus-header" style={{height:'100px'}}>
       <div style={{display:'flex',textAlign:'center',justifyContent:'center',fontSize:'19px'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Contact</div>
</div>
<div className="header-joinus-title" style={{fontSize:'20px'}}>
CONTACT

</div>
</div>
</MobileView>
              {/* <div className="joinus-header">
                     <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}><div>Budget Taxi  </div>   <div style={{ color: '#ffc61a', marginLeft: 10 }}>  // Contact</div>
                     </div>
                     <div className="header-joinus-title">
                            CONTACT

                     </div>
              </div> */}
              <div>
                     <div className="joinus-des">If you need anything from us, feel free to get in touch!</div>
                     <div className="joinus-des" style={{ marginTop: '20px' }}>We are available 24/7</div>
                     <div className="joinus-des" style={{ marginTop: '20px' }}>Call us @ +919894959128</div>
                     <div className="joinus-des" style={{ marginTop: '20px' }}>Call us @ - +918825775128</div>
              </div>
              {/* budgetttaxi@gmail.com */}
              <BrowserView>
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                     <div className="contact-us-contact">
                            <div style={{ marginBottom: '15px' }}><FaLocationArrow size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Head Office:</div>
                            <div className="joinus-des" style={{marginTop:'6px'}} >Mayiladuthurai/Chidambaram, Tamil Nadu 609001</div>
                     </div>

                     <div className="contact-us-contact">
                            <div style={{ marginBottom: '15px' }}><FaPhoneSquareAlt size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Call & Whatsapp:</div>
                            <div className="joinus-des" style={{marginTop:'6px',display:'flex',alignItems:'center'}}><FaSquarePhone style={{marginRight:'2px'}} size={20} />: <span>+919894959128</span></div>

                            <div className="joinus-des" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>     <div>
                                   {/* <FaSquareWhatsapp color="#128C7E" size={24} /> */}
                                   </div>
                                   <div className="joinus-des" style={{display:'flex',alignItems:'center'}}><FaSquareWhatsapp color="#128C7E" size={20} />: +918825775128</div></div>
                            {/* <div className="joinus-des">
                                   Complaints: +919894959128</div> */}
                     </div>

                     <div className="contact-us-contact" style={{padding:'12px'}}>
                            <div style={{ marginBottom: '15px' }}><IoMdMail size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Booking & Enquiry:</div>
                            <div className="joinus-des" style={{marginTop:'6px',fontSize:'15px'}}>budgetttaxi@gmail.com</div>
                            <div className="joinus-des" style={{marginTop:'5px'}}>Support@budget-taxi.com</div>
                            
                     </div>
              </div></BrowserView>

              <MobileView>
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginTop: '20px',flexDirection:'column' }}>
                     <div className="contact-us-contact">
                            <div style={{ marginBottom: '15px' }}><FaLocationArrow size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Head Office:</div>
                            <div className="joinus-des" >Mayiladuthurai/Chidambaram, Tamil Nadu 609001, India</div>
                     </div>

                     <div className="contact-us-contact">
                            <div style={{ marginBottom: '15px' }}><FaPhoneSquareAlt size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Call & Whatsapp:</div>
                            <div className="joinus-des" style={{display:'flex',alignItems:'center'}}><FaSquarePhone style={{marginRight:'2px'}} size={20} />: +919894959128</div>

                            <div className="joinus-des" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>     <div>
                                   </div>
                                   <div className="joinus-des" style={{display:'flex',alignItems:'center'}}><FaSquareWhatsapp color="#128C7E" size={20} />: +918825775128</div></div>
                            {/* <div className="joinus-des">
                                   Complaints: +919894959128</div> */}
                     </div>

                     <div className="contact-us-contact">
                            <div style={{ marginBottom: '15px' }}><IoMdMail size={40} />
                            </div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>Booking & Enquiry:</div>
                            <div className="joinus-des"  style={{marginTop:'6px'}}>budgetttaxi@gmail.com</div>
                            <div className="joinus-des">Support@budget-taxi.com</div>
                     </div>
              </div>
              </MobileView>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '30px' }}>
                     <div className="joinus-des">Social:</div>
                     <div style={{ marginTop: '20px' }}>
                            <FaSquareWhatsapp color="#128C7E" size={30} style={{ marginRight: '10px' }} />
                            <FaInstagramSquare color="#DD2A7B" size={30} style={{ marginRight: '10px' }} />
                            <FaYoutube color="#FF0000" size={30} style={{ marginRight: '10px' }} />
                            <FaFacebookSquare color="#4267B2" size={30} style={{ marginRight: '10px' }} />

                     </div>
              </div>
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginTop: '30px', flexDirection: 'column', alignItems: 'center' }}>

                     <div className="header-title-car">SUGGESTIONS & COMPLAINTS
                     </div>
                     <div className="container-form-contact" style={{ flexDirection: 'column', padding: '24px', position: 'relative' }}>


                            <div style={{ display: 'flex', width: '100%', marginTop: '24px', flexDirection: 'column' }}>

                                   <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                                          <div style={{ width: '100%', }}>
                                                 <div className="joinus-form-des" >Your Name *</div>
                                                 <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Input placeholder="" style={{ height: '45px', width: '90%' }} 
                                                        onChange={(e)=>{
                                                               setstoreState({...storeState,name: e.target.value})
                                                        }}
                                                        />
                                                 </div>
                                          </div>
                                          <div style={{ width: '100%', }}>
                                                 <div className="joinus-form-des" >Your Email *</div>
                                                 <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Input 
                                                         onChange={(e)=>{
                                                               setstoreState({...storeState,email: e.target.value})
                                                        }}
                                                        placeholder="" style={{ height: '45px', width: '90%' }} />
                                                 </div>
                                          </div>
                                   </div>

                                   <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                                          <div style={{ width: '100%', }}>
                                                 <div className="joinus-form-des" >Your Number *</div>
                                                 <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Input 
                                                        onChange={(e)=>{
                                                               setstoreState({...storeState,number: e.target.value})
                                                        }}
                                                        placeholder="" style={{ height: '45px', width: '90%' }} />
                                                 </div>
                                          </div>

                                          <div style={{ width: '100%', }}>
                                                 <div className="joinus-form-des" >Subject *</div>
                                                 <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Input 
                                                        onChange={(e)=>{
                                                               setstoreState({...storeState,subject: e.target.value})
                                                        }}
                                                        placeholder="" style={{ height: '45px', width: '90%' }} />
                                                 </div>
                                          </div>
                                   </div>

                                   <div style={{ width: '100%', marginTop: '20px' }}>
                                          <div className="joinus-form-des" >Message *</div>
                                          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                 <TextArea 
                                                 onChange={(e)=>{
                                                        setstoreState({...storeState,message: e.target.value})
                                                 }}
                                                 rows={7} placeholder="Message" maxLength={56} />
                                          </div>
                                   </div>

                                   <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                                          <Button onClick={() => {
                                             notification.success({
                                                 message:"Success",
                                                 description:"Your query has been submitted successfully, Thank you!"
                                               })
                                               setTimeout(() => {
                                                 sendMessage()
                                               }, 1000);
                                          }} size={'large'} className="get-tax-button">
                                                 Submit
                                          </Button>
                                   </div>



                            </div>
                     </div>
              </div>
              <div style={{ marginTop: '3%' }}>
                     <Footer />
              </div>
              <div class="floating-icon">
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <IoLogoWhatsapp onClick={()=>{
window.open("https://Wa.me/+918825775128", '_blank').focus();
    }} size={40} color="#128C7E"/>
    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaInstagramSquare onClick={()=>{
      window.open("https://www.instagram.com/budget_.taxi?utm_source=qr&igsh=MWFnNGtmM2xteGZxdg==", '_blank').focus();
    }} size={40} color="#E1306C"/>

    </div>

    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaFacebookSquare onClick={()=>{
      window.open("https://www.facebook.com/profile.php?id=61557460117261&mibextid=ZbWKwL", '_blank').focus();
    }} size={40} color="#4267B2" />


    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
      <Image onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=customercare@budget-taxi.com&tf=1", '_blank').focus();
    }} src={require('../../assets/gmail.png')} style={{width:'45px',height:'38px'}} preview={false}/>
    {/* <IoMdMail onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com&tf=1", '_blank').focus();
    }} size={40} color="#ea4335" /> */}


    </div>

</div>
       </div>


}

export default ContactUsScreen