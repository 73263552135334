import logo from './logo.svg';
import './App.css';
import './mobile.css';
import 'antd/dist/reset.css'
import { Route, Routes } from 'react-router-dom';
import JoinUsScreen from './container/pages/join-us-screen';
import HomeScreen from './container/pages/home-screen';
import PayNowScreen from './container/pages/pay-now';
import ContactUsScreen from './container/pages/contact-us';
import TraiffScreen from './container/pages/traiff';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/join-us" element={<JoinUsScreen />} />
      <Route path="/paynow" element={<PayNowScreen />} />
      <Route path="/contact-us" element={<ContactUsScreen />} />
      <Route path="/tariff" element={<TraiffScreen />} />
    </Routes>
  );
}

export default App;
