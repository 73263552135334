import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


const Header =(props)=>{
       let navigate = useNavigate()

       return <div className="row header-container" id="header" style={{position:props?.enableStyle?'relative':'fixed'}}>
              <BrowserView>
              <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
              <div style={{width:'40%',cursor:'pointer'}} onClick={()=>{
                     navigate('/',{state:{showForm:null}})
              }}>
                     <div style={{width:'270px',height:'90px',paddingTop:'10px'}}>
                     <Image preview={false} height={'90px'}  src={require('../../assets/Budget-Taxi-white.png')} />
                     </div>
              </div>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',alignItems:'center',width:'30%'}}>
              <div onClick={()=>{
                           navigate('/',{state:{showForm:Math.random()*73439493}})
                     //       props?.onChangeHeader()
                     }} className="header-title">
                     BOOK NOW
                     </div>
                     <div onClick={()=>{
                           navigate('/tariff')
                     //       props?.onChangeHeader()
                     }} className="header-title">
                     Tariff
                     </div>
                     
                     <div onClick={()=>{
                            navigate('/paynow')
                     }} className="header-title">
                     PAY NOW
                     </div>
                     <div onClick={()=>{
                            navigate('/contact-us')
                     }} className="header-title">
                     CONTACT US
                     </div>
                     <div onClick={()=>{
                            navigate('/join-us')
                     }} className="header-title">
                     JOIN US
                     </div>
              </div>

              </div>
              </BrowserView>
              <MobileView>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
              <div style={{width:'100%',cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                     navigate('/',{state:{showForm:null}})
              }}>
                     <div style={{width:'240px',height:'80px',paddingTop:'10px'}}>
                     <Image preview={false} height={'80px'}  src={require('../../assets/Budget-Taxi-white.png')} />
                     </div>
              </div>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:'18px',paddingRight:'10px'}}>
              <div onClick={()=>{
                           navigate('/',{state:{showForm:Math.random()*73439493}})
                     //       props?.onChangeHeader()
                     }} className="header-title">
                     BOOK NOW
                     </div>
                     <div onClick={()=>{
                           navigate('/tariff')
                     //       props?.onChangeHeader()
                     }} className="header-title">
                     Tariff
                     </div>
                     <div onClick={()=>{
                            navigate('/paynow')
                     }} className="header-title">
                     PAY NOW
                     </div>
                     <div onClick={()=>{
                            navigate('/contact-us')
                     }} className="header-title">
                     CONTACT US
                     </div>
                     <div onClick={()=>{
                            navigate('/join-us')
                     }} className="header-title">
                     JOIN US
                     </div>
              </div>

              </div>   
              </MobileView>
       </div>
}

export default Header;