import { Button, Image, Input, Select } from "antd"
import Header from "../header"
import { useState } from "react"
import TextArea from "antd/es/input/TextArea"
import Footer from "../footer"
import { IoLogoWhatsapp } from "react-icons/io"
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"
import { BrowserView, MobileView, isMobile } from "react-device-detect";


const stateList = [
       "Ariyalur",
       "Chennai",
       "Coimbatore",
       "Cuddalore",
       "Dharmapuri",
       "Dindigul",
       "Erode",
       "Kanchipuram",
       "Kanyakumari",
       "Karur",
       "Krishnagiri",
       "Madurai",
       "Nagapattinam",
       "Namakkal",
       "Nilgiris",
       "Perambalur",
       "Pudukkottai",
       "Ramanathapuram",
       "Salem",
       "Sivaganga",
       "Thanjavur",
       "Theni",
       "Thoothukudi (Tuticorin)",
       "Tiruchirappalli",
       "Tirunelveli",
       "Tiruppur",
       "Tiruvallur",
       "Tiruvannamalai",
       "Tiruvarur",
       "Vellore",
       "Viluppuram",
       "Virudhunagar"
     ]
const PayNowScreen = () =>{
       const [cityList, setcityList] = useState(stateList)
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
const onSearch = (value) => {
       console.log('search:', value);
     };
const generateLableValue =(data,stateName)=>{
let a =[]
cityList.map((value)=> {
if(stateName && value!=stateName){
a.push({value: value,label: value,})
}else if(!stateName){
a.push({value: value,label: value,})
}

})

return a




}
const sendMessage = () => {
  const phoneNumber = '+918825604230'; // Replace with your phone number
  const message = encodeURIComponent('Hello, I am interested in your services.');

  window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
};
       return<> <div >
<Header enableStyle={true} />
<BrowserView>
<div className="joinus-header">
       <div style={{display:'flex',textAlign:'center',justifyContent:'center'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Online Payment</div>
</div>
<div className="header-joinus-title">
ONLINE PAYMENT

</div>
</div>

</BrowserView>
<MobileView>
<div className="joinus-header" style={{height:'100px'}}>
       <div style={{display:'flex',textAlign:'center',justifyContent:'center',fontSize:'19px'}}>
              <div>Budget Taxi  </div>   <div style={{color:'#ffc61a',marginLeft:10}}>  // Online Payment</div>
</div>
<div className="header-joinus-title" style={{fontSize:'20px'}}>
ONLINE PAYMENT

</div>
</div>
</MobileView>
<div>
<div className="header-joinus-title" style={{color:'#161616',fontSize:isMobile?"19px":"30px"}}>
PAYMENT OPTIONS

</div>
       <div className="joinus-des" style={{fontSize:isMobile?"16px":"22px"}}>YOU CAN MAKE PAYMENT BY FOLLOWING METHODS</div>
       {/* <div className="joinus-des" style={{marginTop:'20px'}}>We are available from 10 AM - 11 PM</div> */}
</div>
<div style={{width:'100%',justifyContent:'center',display:'flex',marginTop:'10px',alignItems:'center'}}>
<Image style={{width:'400px',height:'400px',justifyContent: "center",
    display: "flex"}} src={require('../../assets/gpay.jpeg')} className="pay-images" preview={false}  />
          </div>
          <div style={{marginTop:'30px'}}>
          <Footer />
          </div>
          <div class="floating-icon">
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <IoLogoWhatsapp onClick={()=>{
window.open("https://Wa.me/+918825775128", '_blank').focus();
    }} size={40} color="#128C7E"/>
    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaInstagramSquare onClick={()=>{
      window.open("https://www.instagram.com/budget_.taxi?utm_source=qr&igsh=MWFnNGtmM2xteGZxdg==", '_blank').focus();
    }} size={40} color="#E1306C"/>

    </div>

    <div style={{marginRight:'10px',cursor:'pointer'}}>
    <FaFacebookSquare onClick={()=>{
      window.open("https://www.facebook.com/profile.php?id=61557460117261&mibextid=ZbWKwL", '_blank').focus();
    }} size={40} color="#4267B2" />


    </div>
    <div style={{marginRight:'10px',cursor:'pointer'}}>
      <Image onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=iyyappanrock535@gmail.com&tf=1", '_blank').focus();
    }} src={require('../../assets/gmail.png')} style={{width:'45px',height:'38px'}} preview={false}/>
    {/* <IoMdMail onClick={()=>{
      window.open("https://mail.google.com/mail/u/0/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com&tf=1", '_blank').focus();
    }} size={40} color="#ea4335" /> */}


    </div>

</div>
       </div>
       
       </>
}

export default PayNowScreen